import React, { useState } from 'react';
import axios from 'axios';

const SubmittedFormCardCmp = () => {
  return (
    <div className='p-4 text-center text-white rounded bg-primary'>
      <h2>
        Thank you for your submission! We will get back to you as soon as we
        can.
      </h2>
    </div>
  );
};

function ContactFormCmp() {
  const [serverState, setServerState] = useState<{
    submitting: boolean;
    status: null | { ok: boolean; msg: string };
  }>({
    submitting: false,
    status: null,
  });
  const [, setNameValid] = useState(false);
  const [, setEmailValid] = useState(false);
  const [, setPhoneValid] = useState(false);
  const [, setMessageValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    switch (event.target.name) {
      case 'name':
        event.target.value.length > 0
          ? setNameValid(true)
          : setNameValid(false);
        break;
      case 'email':
        event.target.value.length > 0
          ? setEmailValid(true)
          : setEmailValid(false);
        break;
      case 'phone':
        event.target.value.length > 0
          ? setPhoneValid(true)
          : setPhoneValid(false);
        break;
      case 'message':
        event.target.value.length > 0
          ? setMessageValid(true)
          : setMessageValid(false);
        break;
      default:
        break;
    }
  };

  const handleServerResponse = (
    ok: boolean,
    msg: string,
    form: { reset: () => void }
  ) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = (e: { preventDefault: () => void; target: any }) => {
    e.preventDefault();
    const form = e.target;

    setServerState({ submitting: true, status: null });

    axios({
      method: 'post',
      url: 'https://getform.io/f/50983043-0c43-48d6-a69c-2799b009c5c4',
      data: new FormData(form),
    })
      .then(_ => {
        handleServerResponse(true, 'Thanks!', form);
        localStorage.setItem('submitted-form', 'true');
        setSubmitted(true);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <>
      {submitted ? (
        <SubmittedFormCardCmp />
      ) : (
        <form onSubmit={handleOnSubmit}>
          <div className='mx-auto lg:w-1/2 md:w-2/3'>
            <div className='flex flex-wrap -m-2'>
              <div className='w-full p-2'>
                <input
                  className='w-full px-4 py-2 text-base bg-gray-100 border border-gray-400 rounded focus:outline-none focus:border-secondary'
                  placeholder='Name'
                  name='name'
                  type='text'
                  onChange={e => handleInputChange(e)}
                  required
                ></input>
              </div>
              <div className='w-full p-2'>
                <input
                  className='w-full px-4 py-2 text-base bg-gray-100 border border-gray-400 rounded focus:outline-none focus:border-secondary'
                  placeholder='Email'
                  name='email'
                  type='email'
                  onChange={e => handleInputChange(e)}
                  required
                ></input>
              </div>
              <div className='w-full p-2'>
                <input
                  className='w-full px-4 py-2 text-base bg-gray-100 border border-gray-400 rounded focus:outline-none focus:border-secondary'
                  placeholder='Phone'
                  name='phone'
                  type='phone'
                  onChange={e => handleInputChange(e)}
                  required
                ></input>
              </div>
              <div className='w-full p-2'>
                <textarea
                  className='block w-full h-48 px-4 py-2 text-base bg-gray-100 border border-gray-400 rounded resize-none focus:outline-none focus:border-secondary'
                  placeholder='Message'
                  name='message'
                  onChange={e => handleInputChange(e)}
                  required
                ></textarea>
              </div>
              {serverState.submitting ? (
                <div className='flex items-center justify-center w-full h-full p-2'>
                  <img
                    className='w-16 h-16'
                    src='https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif'
                    alt=''
                  />
                </div>
              ) : (
                <div className='w-full p-2'>
                  <button
                    className='flex px-8 py-2 mx-auto text-lg text-white border-0 rounded bg-primary hover:bg-secondary focus:outline-none disabled:opacity-5 disabled:cursor-not-allowed'
                    type='submit'
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default ContactFormCmp;
